import React, { useContext } from "react";
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { FaFacebook, FaTwitter } from "react-icons/fa";
import useTranslations from "components/hooks/useTranslations";
import MailingList from "components/mailing-list";
import { LocaleContext } from "context/locale-context";
import LocalizedLink from "components/localizedLink";
import { colors } from "style/theme";
import { rhythm } from "utils/typography";
import { mq } from "utils/helper";

const P = styled.p((props) => ({
  color: colors.light,
  fontSize: "85%",
  letterSpacing: props.isRTL ? "normal" : "0.77px",
  lineHeight: "24px",
}));

const Link = styled.a({
  fontSize: "85%",
  fontWeight: 500,
  textTransform: "uppercase",
  color: colors.light,
  display: "block",
  transition: "0.2s",
  ":hover": {
    color: colors.primary,
  },
});

function Footer() {
  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";
  const tr = useTranslations();

  return (
    <footer
      css={{
        width: "100%",
        borderTop: `1px solid ${colors.primary}`,
        backgroundColor: colors.dark,
        paddingTop: rhythm(3),
        paddingBottom: rhythm(2),
        direction: isRTL ? "rtl" : "ltr",
        [mq[0]]: {
          paddingTop: rhythm(0.5),
        },
      }}
    >
      <div
        css={{
          display: "grid",
          gridTemplateColumns: "1.3fr 0.6fr 0.7fr 1fr",
          gridTemplateAreas: '"syrianarchive links mnemonic social"',
          gridColumnGap: rhythm(1),
          margin: "0 auto",
          maxWidth: rhythm(51.35),
          color: colors.light,
          "& li": {
            listStyle: "none",
          },
          [mq[0]]: {
            display: "block",
            margin: rhythm(1),
          },
        }}
      >
        <div css={{ gridArea: "syrianarchive", maxWidth: "80%" }}>
          <div
            css={{
              display: "flex",
              alignItems: "center",
              marginBottom: rhythm(1),
              [mq[0]]: {},
            }}
          >
            <a href={isRTL ? "/ar" : "/"}>
              <img
                src="/assets/logo.svg"
                height="10%"
                alt="logo"
                css={{
                  marginBottom: 0,
                }}
              />
            </a>
          </div>
          <div>
            <P isRTL={isRTL}>{tr("layout-footer")}</P>
            <a
              href="https://patreon.com/mnemonicorg"
              rel="noopener noreferrer"
              target="_blank"
              css={{
                color: colors.light,
                border: `3px solid ${colors.primary}`,
                padding: "0.5rem 2rem 0.2rem 2rem",
                ":hover, :active": {
                  //                  backgroundColor: colors.light,
                  //                  color: colors.dark
                },
              }}
            >
              {tr("Donate")}
            </a>
          </div>
        </div>

        <div
          css={{
            gridArea: "links",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "120px",
            alignSelf: "center",
            [mq[1]]: {
              marginTop: rhythm(2),
              marginBottom: rhythm(2),
            },
          }}
        >
          <Link as={LocalizedLink} to="about">
            {tr("about")}
          </Link>
          <Link as={LocalizedLink} to="contact-us">
            {tr("contact")}
          </Link>
          <Link as={LocalizedLink} to="about/press">
            {tr("press")}
          </Link>
          <Link as={LocalizedLink} to="about/privacy">
            {tr("privacy policy")}
          </Link>
          <Link as={LocalizedLink} to="about/imprint">
            {tr("imprint")}
          </Link>
        </div>

        <div
          css={{
            gridArea: "mnemonic",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "120px",
            alignSelf: "center",
          }}
        >
          <Link
            href="https://syrianarchive.org/"
            rel="noopener noreferrer"
            target="_blank"
          >
            {tr("Syrian Archive")}
          </Link>
          <Link
            href="https://sudanesearchive.org/"
            rel="noopener noreferrer"
            target="_blank"
          >
            {tr("Sudanese Archive")}
          </Link>
          <Link
            href="https://yemeniarchive.org/"
            rel="noopener noreferrer"
            target="_blank"
          >
            {tr("Yemeni Archive")}
          </Link>
          <Link
            href="https://ukrainianarchive.org/"
            rel="noopener noreferrer"
            target="_blank"
          >
            {tr("Ukrainian Archive")}
          </Link>
        </div>

        <div
          css={{
            gridArea: "social",
            height: "120px",
            alignSelf: "center",
            [mq[1]]: {
              marginTop: rhythm(2),
            },
          }}
        >
          <MailingList />
          <div
            css={{
              fontSize: "2.2rem",
              display: "flex",
              maxWidth: "60%",
              marginTop: rhythm(1.2),
            }}
          >
            <Link
              aria-label="facebook account"
              href="https://facebook.com/mnemonicorg"
              css={{ marginRight: "2rem" }}
            >
              <FaFacebook />
            </Link>
            <Link
              aria-label="twitter account"
              href="https://twitter.com/mnemonicorg"
            >
              <FaTwitter />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
