import React, { useContext, useState } from "react";
import styled from "@emotion/styled";
import useTranslations from "components/hooks/useTranslations";
import { LocaleContext } from "context/locale-context";
import { rhythm } from "utils/typography";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { colors } from "style/theme";

const H5 = styled.h5({
  fontWeight: "bold",
  letterSpacing: "0.88px",
  color: colors.primary
});

function MailingList() {
  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";
  const tr = useTranslations();

  const [email, setEmail] = useState("");
  const [result, setResult] = useState("");

  const handleEmailChange = e => {
    setEmail(e.target.value);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const submitResult = await addToMailchimp(email);
      setResult(submitResult);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        required
        css={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <label>
          <H5
            css={{
              fontSize: "85%",
              marginTop: 0
            }}
          >
            {tr("Subscribe to our mailing list")}
          </H5>
          <input
            type="email"
            name="email"
            placeholder={tr("Email address")}
            onChange={handleEmailChange}
            css={{
              backgroundColor: colors.dark,
              padding: "0.7rem 0.5rem 0.5rem 0.5rem",
              color: colors.light,
              border: `3px solid ${colors.light}`,
              height: rhythm(1.2),
              width: "100%"
            }}
          ></input>
        </label>
        <button
          type="submit"
          css={{
            backgroundColor: colors.dark,
            height: rhythm(1.2),
            color: colors.light,
            border: `3px solid ${colors.light}`,
            padding: "0.1rem 0.3rem 0 0.3rem",
            alignSelf: "flex-end",
            transition: "0.25s",
            ":hover, :active": {
              backgroundColor: colors.light,
              color: colors.dark,
              cursor: "pointer"
            }
          }}
        >
          {tr("Submit")}
        </button>
      </form>
      {result.msg}
    </>
  );
}

export default MailingList;
