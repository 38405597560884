import React, { useContext, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import LocalizedLink from "components/localizedLink";
import useTranslations from "components/hooks/useTranslations";
import { MdMenu, MdClose } from "react-icons/md";
import { LocaleContext } from "context/locale-context";
import styled from "@emotion/styled";
import { rhythm } from "utils/typography";
import MdxLink from "../components/mdxLink";
import { colors } from "style/theme";
import { jsx } from "@emotion/core";
import { mq } from "utils/helper";

const assignActiveStyles = ({ isPartiallyCurrent }) =>
  isPartiallyCurrent ? { style: { color: "colors.primary" } } : {};

const Item = styled.li(props => ({
  fontSize: "18.5px",
  margin: 0,
  color: colors.light,
  paddingTop: props.mobile ? rhythm(1.3) : "initial",
  textTransform: "uppercase",
  transition: "0.25s",
  "a:hover, a:active": {
    color: colors.primary
  }
}));

const navItems = [{ name: "Our Work", to: "our-work", id: 0 }];

function DropdownMenu({ title, items, isDropped, setIsDropped }) {
  const tr = useTranslations();

  return (
    <div css={{ position: "relative" }}>
      <Item
        onClick={() => setIsDropped(!isDropped)}
        css={{
          cursor: "pointer",
          transition: "0.25s",
          ":hover": {
            color: colors.primary
          }
        }}
      >
        {tr(title)}
      </Item>
      {isDropped ? (
        <div
          css={{
            position: "absolute",
            top: 30,
            left: -150,
            backgroundColor: colors.dark,
            minWidth: "200px",
            padding: `${rhythm(0.8)} ${rhythm(1)}`,
            border: `3px solid ${colors.primary}`,
            transition: "0.25s",
            color: colors.light
          }}
        >
          <ul
            css={{
              listStyle: "none",
              marginBottom: 0,
              "li: last-of-type": {
                marginBottom: 0
              }
            }}
          >
            {items.map(item => (
              <li key={item}>
                <LocalizedLink
                  css={{
                    color: colors.light,
                    ":hover, :active": { color: colors.primary }
                  }}
                  to={item.to}
                >
                  {item.title}
                </LocalizedLink>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

function MobileNav({ tr, isRTL }) {
  const mobileNav = [
    { name: "Our work", to: "our-work" },
    { name: "News", to: "news" },
    { name: "Help Desk", to: "help-desk" },
    { name: "Press", to: "about/press" },
    { name: "About", to: "about" },
    { name: "Legal", to: "about/legal" },
    { name: "Team", to: "team" },
    { name: "Donate", to: "https://patreon.com/mnemonicorg" }
  ];
  return (
    <nav
      css={{
        maxHeight: "75vh"
      }}
    >
      <ul
        css={{
          listStyle: "none",
          "li: last-of-type": {
            marginTop: rhythm(1),
            padding: rhythm(1 / 4),
            paddingLeft: 0,
            display: "inline-block",
            border: `3px solid ${colors.dark}`
          },
          [mq[1]]: {
            marginRight: isRTL ? rhythm(2) : 0
          }
        }}
      >
        {mobileNav.map(item => (
          <Item mobile key={item.id}>
            <MdxLink href={item.to} css={{ color: colors.light }}>
              {tr(item.name)}
            </MdxLink>
          </Item>
        ))}
      </ul>
    </nav>
  );
}

function MobileHeader({ tr, isRTL }) {
  const [isNavCollapsed, setIsNavCollapsed] = useState(false);

  return (
    <header
      css={{
        display: "none",
        position: "sticky",
        top: 0,
        let: 0,
        backgroundColor: colors.dark,
        borderBottom: `3px solid ${colors.primary}`,
        zIndex: 10,
        direction: isRTL ? "rtl" : "ltr",
        [mq[2]]: {
          display: "block",
          paddingTop: rhythm(0.3),
          paddingBottom: rhythm(0.3)
        }
      }}
    >
      <div
        css={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          padding: rhythm(0.25)
        }}
      >
        <div
          css={{
            display: "flex",
            alignItems: "center",
            textTransform: "uppercase"
          }}
        >
          <Link
            css={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              [mq[0]]: {
                marginLeft: rhythm(1)
              }
            }}
            to={isRTL ? "/ar" : "/"}
          >
            <img
              src="/assets/logo.svg"
              height="100%"
              alt="logo"
              css={{ marginBottom: 0 }}
            />
          </Link>
        </div>
        <div
          onClick={() => setIsNavCollapsed(!isNavCollapsed)}
          css={{
            cursor: "pointer",
            color: colors.light,
            marginLeft: isRTL ? 0 : "auto",
            marginRight: isRTL ? "auto" : 0,
            fontSize: "160%",
            display: "flex"
          }}
        >
          {isNavCollapsed ? <MdClose /> : <MdMenu />}
        </div>
      </div>
      {isNavCollapsed && <MobileNav tr={tr} isRTL={isRTL} />}
    </header>
  );
}

function DesktopNav({ tr }) {
  const [isAboutDropped, setIsAboutDropped] = useState(false);
  const [isEngageDropped, setIsEngageDropped] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsAboutDropped(false);
        setIsEngageDropped(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <nav
      ref={wrapperRef}
      css={{
        [mq[2]]: {
          display: "none"
        }
      }}
    >
      <ul
        css={{
          display: "flex",
          justifyContent: "space-between",
          width: "600px",
          listStyleType: "none",
          margin: 0
        }}
      >
        {navItems.map(item => (
          <Item key={item.id}>
            <LocalizedLink
              to={item.to}
              getProps={assignActiveStyles}
              css={{
                color: colors.light
              }}
            >
              {tr(item.name)}
            </LocalizedLink>
          </Item>
        ))}
        <div onClick={() => setIsAboutDropped(false)}>
          <DropdownMenu
            isDropped={isEngageDropped}
            setIsDropped={setIsEngageDropped}
            items={[
              { title: "News", to: "news" },
              { title: "Help Desk", to: "help-desk" },
              { title: "Contact us", to: "contact-us" }
            ]}
            title="Engage"
          />
        </div>
        <div onClick={() => setIsEngageDropped(false)}>
          <DropdownMenu
            isDropped={isAboutDropped}
            setIsDropped={setIsAboutDropped}
            items={[
              { title: "About", to: "about" },
              { title: "Methods", to: "about/methods" },
              { title: "Team", to: "team" },
              { title: "Jobs", to: "about/jobs" }
            ]}
            title="About"
          />
        </div>
        <Item
          css={{
            display: "flex",
            alignItems: "center",
            transition: "0.25s",
            "a:hover, a:active": {
              //backgroundColor: colors.dark,
              color: colors.light
            }
          }}
        >
          <a
            href="https://patreon.com/mnemonicorg"
            rel="noopener noreferrer"
            target="_blank"
            css={{
              fontSize: "75%",
              color: colors.light,
              transition: "0.30s",
              border: `3px solid ${colors.primary}`,
              padding: "0.4rem 0.8rem 0.2rem 0.8rem",
              marginTop: "-0.5rem"
            }}
          >
            {tr("Donate")}
          </a>
        </Item>
      </ul>
    </nav>
  );
}

function DesktopHeader({ tr, isRTL }) {
  return (
    <header
      css={{
        maxWidth: "1400px",
        margin: "auto",
        //backgroundColor: "#000",
        color: colors.light,
        direction: isRTL ? "rtl" : "ltr",
        [mq[2]]: {
          display: "none"
        }
      }}
    >
      <div
        css={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          //          minHeight: rhythm(5),
          position: "relative",
          padding: `${rhythm(3)} ${rhythm(3.5)}`,
          textTransform: "uppercase",
          fontWeight: "500",
          [mq[0]]: {
            padding: rhythm(0.25),
            minHeight: rhythm(3)
          }
        }}
      >
        <Link
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            [mq[0]]: {
              marginLeft: rhythm(1)
            }
          }}
          to={isRTL ? "/ar" : "/"}
        >
          <img
            src="/assets/logo.svg"
            alt="logo"
            height="100%"
            css={{ marginBottom: 0 }}
          />
        </Link>
        <DesktopNav tr={tr} />
      </div>
    </header>
  );
}

function Header() {
  const tr = useTranslations();
  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";

  return (
    <div>
      <DesktopHeader tr={tr} isRTL={isRTL} />
      <MobileHeader tr={tr} isRTL={isRTL} />
    </div>
  );
}

MobileNav.propTypes = {
  tr: PropTypes.func
};

MobileHeader.propTypes = {
  tr: PropTypes.func,
  isRTL: PropTypes.bool
};

DesktopNav.propTypes = {
  tr: PropTypes.func
};

DesktopHeader.propTypes = {
  tr: PropTypes.func,
  isRTL: PropTypes.bool
};

export default Header;
