import React from "react";
import PropTypes from "prop-types";
import Footer from "components/Footer";
import Header from "components/Header";

function Layout({ children }) {
  return (
    <div css={{ position: "relative", zIndex: 2 }}>
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default Layout;
